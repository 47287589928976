<template>
  <v-card
    elevation="2"
    height="680px"
    class="my-0"
  >
    <v-card-text
      id="cardID"
      class="overflow-auto pa-0"
      style="height: 92%;"
    >
      <v-img
        v-if="image"
        id="mainImg"
        ref="image"
        :src="image"
        :style="imgRotateStyle"
        contain
      />
      <pdf
        v-if="pdf"
        ref="pdf"
        style="border: 1px solid darkgrey; width:100%"
        :style="zoom != 0 ? 'transform-origin: 0 0; transform: scale(' + zoom + ')' : ''"
        :src="pdf"
        :page="pdfConfig.page"
        :rotate="pdfConfig.rotate"
        @drag="changeposition"
        @progress="pdfConfig.loadedRatio = $event"
        @num-pages="pdfConfig.numPages = $event"
        @link-clicked="pdfConfig.page = $event"
      />
    </v-card-text>
    <v-card-actions v-if="image||pdf">
      <v-btn
        text
        @click="printDoc()"
      >
        <v-icon>mdi-printer</v-icon>печать
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="download"
      >
        <v-icon>mdi-download</v-icon>Сохранить
      </v-btn>
      <span
        v-if="!image"
        class="d-flex"
      >
        <v-btn
          text
          :disabled="pdfConfig.page===1"
          @click="pdfConfig.page--"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div class="py-3 px-5">{{ pdfConfig.page }}/{{ pdfConfig.numPages }}</div>
        <v-btn
          text
          :disabled="pdfConfig.page===pdfConfig.numPages"
          @click="pdfConfig.page++"
        ><v-icon>mdi-arrow-right</v-icon></v-btn>
      </span>
      <v-spacer />
      <v-btn
        text
        @click="zoom += 0.1"
      >
        <v-icon>mdi-magnify-plus-outline</v-icon>
      </v-btn>
      <v-btn
        text
        @click="zoom = 0"
      >
        <v-icon>mdi-magnify-scan</v-icon>
      </v-btn>
      <v-btn
        text
        @click="zoom -= 0.1"
      >
        <v-icon>mdi-magnify-minus-outline</v-icon>
      </v-btn>
      <v-btn
        text
        @click="rotateLeft()"
      >
        <v-icon>mdi-rotate-left</v-icon>
      </v-btn>
      <v-btn
        text
        @click="rotateRight()"
      >
        <v-icon>mdi-rotate-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import pdf from 'vue-pdf'

  export default {
    name: 'PdfViewer',
    components: { pdf },
    props: {
      pdf: {
        type: String,
        default: null,
      },
      image: {
        type: String,
        default: null,
      },
      imgSelect: {
        type: Object,
        default: () => {},
      },
    },
    data: () => ({
      zoom: 1,
      pdfConfig: {
        page: 1,
        rotate: 0,
        loadedRatio: 2,
        numPages: 0,
      },
    }),
    computed: {
      imgRotateStyle () {
        if (this.image) {
          return this.pdfConfig.rotate !== 0 ? 'transform-origin: top left; transform: rotate(' + this.pdfConfig.rotate + 'deg)' : ''
        } else {
          return null
        }
      },
    },
    mounted () {
      const ele = document.getElementById('cardID')
      ele.style.cursor = 'grab'

      let pos = { top: 0, left: 0, x: 0, y: 0 }

      const mouseDownHandler = function (e) {
        ele.style.cursor = 'grabbing'
        ele.style.userSelect = 'none'

        pos = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY,
        }

        document.addEventListener('mousemove', mouseMoveHandler)
        document.addEventListener('mouseup', mouseUpHandler)
      }

      const mouseMoveHandler = function (e) {
        // How far the mouse has been moved
        const dx = e.clientX - pos.x
        const dy = e.clientY - pos.y

        // Scroll the element
        ele.scrollTop = pos.top - dy
        ele.scrollLeft = pos.left - dx
      }
      const mouseUpHandler = function () {
        ele.style.cursor = 'grab'
        ele.style.removeProperty('user-select')
        document.removeEventListener('mousemove', mouseMoveHandler)
        document.removeEventListener('mouseup', mouseUpHandler)
      }
      // Attach the handler
      ele.addEventListener('mousedown', mouseDownHandler)
    },
    methods: {
      rotateLeft () {
        this.pdfConfig.rotate -= 90
      },
      rotateRight () {
        this.pdfConfig.rotate += 90
      },
      printDoc () {
        if (this.pdf) {
          this.$refs.pdf.print()
        } else if (this.image) {
          const iframe = document.createElement('iframe')
          // Make it hidden
          iframe.style.height = 0
          iframe.style.visibility = 'hidden'
          iframe.style.width = 0
          // Set the iframe's source
          iframe.setAttribute('srcdoc', '<html><body></body></html>')
          document.body.appendChild(iframe)
          iframe.addEventListener('load', () => {
            // Clone the image
            const img = document.createElement('img')
            img.src = this.image
            img.style = this.imgRotateStyle
            img.style.width = '100%'
            // Append the image to the iframe's body
            const body = iframe.contentDocument.body
            body.style.textAlign = 'center'
            body.appendChild(img)
            img.addEventListener('load', function () {
              // Invoke the print when the image is ready
              iframe.contentWindow.print()
            })
          })
          iframe.contentWindow.addEventListener('afterprint', function () {
            iframe.parentNode.removeChild(iframe)
          })
          // const w = window.open(this.image)
          // w.print()
        }
      },
      needDot (ext) {
        if (ext && ext[0] === '.') return ''
        return '.'
      },
      print (el) {
        this.d.print(el)
      },
      download () {
        const link = document.createElement('a')
        if (this.image) {
          link.href = this.image
        } else if (this.pdf) {
          link.href = this.pdf
        } else {
          link.href = this.fileURL
        }
        link.setAttribute('download', this.imgSelect.filename + this.needDot(this.imgSelect.fileextension) + this.imgSelect.fileextension)
        document.body.appendChild(link)
        link.click()
      },
    },
  }
</script>
